<template>
  <div>
    <v-expansion-panels accordion v-model="panel" data-cy="spinful_site_params">
      <v-expansion-panel expand v-model="panel">
        <v-expansion-panel-header class="font-weight-bold text">
          Spinful Site Parameters
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h4 class="text" v-if="hasSpinChannel">Spin Channel Notation:</h4>
          <v-data-table
            v-if="hasSpinChannel"
            dense
            class="mb-7"
            :hide-default-footer="true"
            :headers="headers_e"
            :items="getSitesTable"
            :item-class="itemRowBackground"
          >
          </v-data-table>
          <h4 class="text">Magnetic Field Notation:</h4>
          <v-data-table
            dense
            :hide-default-footer="true"
            :headers="headers"
            :items="getSitesTable"
            :item-class="itemRowBackground"
          >
          </v-data-table>
          <v-row class="mt-3">
            <v-spacer />
            <DialogMagneticField />
            <InfoDialog info="allMagFields"></InfoDialog>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ '../InfoDialog.vue')
const DialogMagneticField = () =>
  import(/* webpackChunkName: "dialogs" */ '../MagneticFieldDialog.vue')
const { /* mapMutations, */ mapGetters, mapState } = createNamespacedHelpers(
  'lattice'
)

export default {
  components: {
    InfoDialog,
    DialogMagneticField,
  },

  data: () => ({
    panel: 0,
  }),

  methods: {
    itemRowBackground: function (item) {
      if (item.id % 2) {
        return ['white']
      } else {
        return ['blue lighten-5']
      }
    },
  },

  computed: {
    ...mapGetters(['getSitesTable']),

    ...mapState({
      hasSpinChannel: (state) => state.hasSpinChannel,
    }),

    headers_e() {
      let headers = [
        {
          text: 'id',
          sortable: false,
          value: 'id',
          divider: true,
          width: '20%',
        },
        {
          text: 'name',
          sortable: false,
          value: 'name',
          divider: true,
          width: '20%',
        },
        {
          text: '↑↑',
          sortable: false,
          value: 'e_uu',
          divider: true,
          width: '20%',
        },
        {
          text: '↓↓',
          sortable: false,
          value: 'e_dd',
          divider: true,
          width: '20%',
        },
        {
          text: '↑↓',
          sortable: false,
          value: 'e_ud',
          divider: true,
          width: '20%',
        },
      ]
      return headers
    },

    headers() {
      let headers = [
        {
          text: 'id',
          sortable: false,
          value: 'id',
          divider: true,
          width: '16.6%',
        },
        {
          text: 'name',
          sortable: false,
          value: 'name',
          divider: true,
          width: '16.6%',
        },
        {
          text: 'Onsite Energy',
          sortable: false,
          value: 'e0',
          divider: true,
          width: '16.6%',
        },
        {
          text: 'Bx',
          sortable: false,
          value: 'Bx',
          divider: true,
          width: '16.6%',
        },
        {
          text: 'By',
          sortable: false,
          value: 'By',
          divider: true,
          width: '16.6%',
        },
        {
          text: 'Bz',
          sortable: false,
          value: 'Bz',
          divider: true,
          width: '16.6%',
        },
      ]
      return headers
    },
  },
}
</script>

<style scoped>
.row-color {
  color: blue !important;
}

.v-expansion-panel-header {
  padding-right: -5px !important;
  padding-left: -5px !important;
  white-space: nowrap;
}
</style>
